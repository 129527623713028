<template>
	<div class="police-box">
		<div class="box-title">
			<span class="title-text">事件列表</span>
			<div class="right">
				<!-- <el-select v-model="type" placeholder="请选择报警类型">
					<el-option v-for="(item,index) in typeOptions" :key="index" :label="item.name" :value="item.value"></el-option>
				</el-select> -->
				<el-button v-if="allowAdd" type="primary" icon="el-icon-plus" size="small" @click="openAdd"></el-button>
			</div>
		</div>
		<div class="list-box">
			<ul class="head-ul">
				<li class="width-s">序号</li>
				<li class="time-l">爆破时间</li>
				<li class="width-xl align-left text-overflow">项目名称</li>
				<li class="width-xl align-left text-overflow">爆破单位</li>
				<li class="width-xl align-left text-overflow">监测单位</li>
				<li class="width-icon">事件</li>
				<li class="width-l">报告</li>
				<li class="time">事件时间</li>
			</ul>
			<div class="scroll-box">
				<vue-scroll v-show="item.total">
					<ul class="conent-ul" v-for="(i, index) in item.rows" :key="index">
						<li class="width-s">{{ index + 1 }}</li>
						<li class="time-l">{{ i.dtime }}</li>
						<li class="width-xl align-left text-overflow">{{ i.pjname }}</li>
						<li class="width-xl align-left text-overflow">{{ i.bcname }}</li>
						<li class="width-xl align-left text-overflow">{{ i.cname }}</li>
						<li class="width-icon icon-but" @click="theSelected($event)">
							<i class="el-icon-document pointer view" @click="openDetails(i, $event)"></i>
							<i class="el-icon-delete pointer red-color" v-show="i.examine != 1 && i.uid == 1" @click="deleteEvent(i, $event)"></i>
						</li>
						<li class="width-l txt-but" @click="theSelected($event)">
							<!-- tag  1 文字无操作（待处理）  6 预览下载  3 作报告  2 修改报告（弹窗样式和3一样） 4 文字无操作（无报告） 5 查看报告 （待审批）  -->
							<span v-show="i.examine == 0">暂无报告</span>
							<el-button size="mini" v-show="i.examine == 1" @click="preview(i, $event)" title="预览报告">预览</el-button>
							<el-button size="mini" v-show="i.examine == 1" @click="downloadWord(i.rpid, $event)" title="下载报告">下载</el-button>
						</li>
						<li class="time">{{ i.ctime }}</li>
					</ul>
				</vue-scroll>
				<noData v-if="!item.total" />
			</div>
		</div>
		<div class="bottom-box">
			<div class="sizeBox">
				<span>单页显示条数</span>
				<input type="number" v-model="changeSize" autocomplete="off" />
			</div>

			<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
		</div>
		<!-- 事件列表详情、编辑、新增 -->
		<el-dialog :title="dialogName" :visible.sync="dialogDetails" custom-class="detailsDialog" :modal="false" @close="closeDialog">
			<div class="details">
				<div class="cell-box" v-show="!addShow">
					<!-- 			<div class="cell" v-show="addShow">
						<p class="title">检测单位：</p>
						<el-select v-model="editorInfo.cid" clearable placeholder="请选择" @change="detectionUnitSelect">
							<el-option v-for="(item, index) in detectionUnit" :key="index" :label="item.text" :value="item.id"></el-option>
						</el-select>
					</div> -->
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							项目名称：
						</p>
						<!-- <el-select v-show="addShow" :disabled="projectName.length == 0" v-model="editorInfo.pjid" clearable placeholder="请选择">
							<el-option v-for="(item, index) in projectName" :key="index" :label="item.text" :value="item.id"></el-option>
						</el-select> -->
						<p class="content text-overflow" v-show="!addShow">{{ editorInfo.pjname }}</p>
					</div>
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							爆破单位：
						</p>
						<p class="content text-overflow">{{ editorInfo.bcname }}</p>
					</div>
				</div>
				<div class="cell-box" v-show="!addShow">
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							检测单位：
						</p>
						<p class="content text-overflow">{{ editorInfo.cname }}</p>
					</div>
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							上报时间：
						</p>
						<p class="content text-overflow">{{ editorInfo.ctime }}</p>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							起爆坐标：
						</p>
						<el-input :class="operationShow ? '' : 'show'" :readonly="!operationShow" v-model="editorInfo.coordinates" :placeholder="operationShow ? '请输入' : ''">
							<el-popover slot="append" placement="top" width="170" trigger="hover">
								<div>
									<el-button type="primary" size="mini" @click="getLocation">自动定位</el-button>
									<el-button size="mini" @click="openMap">地图选点</el-button>
								</div>
								<el-button size="mini" icon="el-icon-location-outline" slot="reference"></el-button>
							</el-popover>
							<!-- <el-button @click="getLocation"></el-button> -->
						</el-input>
					</div>
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							位置描述：
						</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.location"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							爆破时间：
						</p>
						<el-date-picker
							:class="operationShow ? 'input-w-xl' : 'input-w-xl show'"
							:readonly="!operationShow"
							v-model="editorInfo.dtime"
							format="yyyy-MM-dd HH:mm"
							value-format="yyyyMMddHHmm"
							type="datetime"
							:placeholder="operationShow ? '选择日期时间' : ''"
							@focus="setTime"
							popper-class="reportTime"
						></el-date-picker>
					</div>
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							<span class="letter">天气</span>
							：
						</p>
						<el-select v-show="editorShow || addShow" v-model="editorInfo.weather" clearable placeholder="请选择">
							<el-option v-for="item in weatherList" :key="item.value" :label="item.name" :value="item.name"></el-option>
						</el-select>
						<span v-show="!editorShow && !addShow">
							<i v-show="editorInfo.weather.indexOf('晴') != -1" class="iconfont sunny icon-qing"></i>
							<i v-show="editorInfo.weather.indexOf('阴') != -1" class="iconfont info icon-yintian"></i>
							<i v-show="editorInfo.weather.indexOf('雨') != -1" class="iconfont rain icon-baoyudaodabaoyu"></i>
						</span>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							爆破类型：
						</p>
						<el-select
							:class="operationShow ? '' : 'show'"
							:disabled="!operationShow"
							v-model="editorInfo.explosive"
							clearable
							:placeholder="operationShow ? '请选择' : ''"
						>
							<el-option v-for="item in typesOfExplosiveList" :key="item.value" :label="item.name" :value="item.value"></el-option>
						</el-select>
					</div>
					<div class="cell">
						<p class="title">
							<span class="tips-i">*</span>
							爆破方式：
						</p>
						<el-select :class="operationShow ? '' : 'show'" :disabled="!operationShow" v-model="editorInfo.mode" clearable :placeholder="operationShow ? '请选择' : ''">
							<el-option v-for="item in detonationWayList" :key="item.value" :label="item.name" :value="item.value"></el-option>
						</el-select>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell long-title">
						<p class="title">
							<span class="tips-i">*</span>
							单段最大装药量（kg）
						</p>
						<el-input
							type="number"
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.md"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
					<div class="cell long-title-s">
						<p class="title">
							<span class="tips-i">*</span>
							爆破总药量（kg）
						</p>
						<el-input
							type="number"
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.ad"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell-s">
						<p class="title">炮孔（个）</p>
						<el-input
							type="number"
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.hn"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">孔深（m）</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.hd"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">孔径（mm）</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.diameter"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
				</div>
				<div class="cell-box">
					<div class="cell-s">
						<p class="title">孔距（m）</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.distance"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">排距（m）</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.rd"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
					<div class="cell-s">
						<p class="title">填塞（m）</p>
						<el-input
							:class="operationShow ? '' : 'show'"
							:readonly="!operationShow"
							v-model="editorInfo.fl"
							:placeholder="operationShow ? '请输入' : ''"
						></el-input>
					</div>
				</div>
				<div class="img-box">
					<el-image v-show="!operationShow" fit="scale-down" id="sceneImg" style="width: 100%;height: 100%;" :src="imgList" :preview-src-list="[imgList]">
						<div slot="error" class="image-slot">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<upload-img v-show="operationShow" ref="uploadImgs" :configuration="parameterOne"></upload-img>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button v-show="!editorShow && allowedToChange && !addShow" @click="modifyStart">修改</el-button>
				<el-button v-show="editorShow" @click="confirmEditor">确认</el-button>
				<el-button v-show="addShow" @click="addEvent">确认</el-button>
				<el-button type="primary" @click="dialogDetails = false">关闭</el-button>
			</div>
		</el-dialog>
		<!-- 报告预览 -->
		<div class="preview-box Mask-box" v-if="previewStatus">
			<div class="pop-ups-content box-center">
				<div class="mian-box">
					<div class="left-box"><iframe frameborder="0" scrolling="auto" :src="src" width="1120px" height="100%" /></div>
					<div class="right-box">
						<el-button size="small" @click="downloadPDF">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span class="txt">导出PDF</span>
							</div>
						</el-button>
						<el-button size="small" @click="downloadWord(rpid)">
							<div class="but-content">
								<span class="icon iconfont icon-daochu"></span>
								<span class="txt">导出word</span>
							</div>
						</el-button>
						<el-button size="small" @click="cancel">
							<div class="but-content">
								<span class="icon iconfont icon-cancel"></span>
								<span class="txt">关闭</span>
							</div>
						</el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 地图选点 -->
		<el-dialog title="地图选点" :visible.sync="dialogMap" custom-class="mapDialog" :modal="false" @close="closeMap">
			<div class="pop-ups-content">
				<div class="input left">
					<el-input v-model="inputName" placeholder="可输入查询城市名"></el-input>
					<el-button type="primary" size="small" :disabled="inputName == ''" @click="inquirePoint">确定</el-button>
				</div>
				<div class="input right">
					经纬度：
					<el-input v-model="selectPoint" readonly placeholder="当前选点经纬度"></el-input>
					<el-button type="primary" size="small" @click="Assignment">确定</el-button>
				</div>
				<div id="pick-point"></div>
			</div>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button @click="dialogMap = false">取 消</el-button>
				<el-button type="primary">确 定</el-button>
			</div> -->
		</el-dialog>
	</div>
</template>

<script>
// 图片上传
import uploadImg from '../../components/uploadImg.vue';
export default {
	name: '',
	data() {
		return {
			item: {
				rows: [], //列表数据
				page: 1,
				size: 30,
				allPage: 0,
				total: 0,
				time: false,
				type: 'event',
				dataType: 'list',
				text: '暂时没有数据哦--'
			},
			changeSize: 30,
			parameter: {}, // 请求参数
			dialogDetails: false, //事件详情弹窗是否显示
			dialogName: '上报事件', //弹窗名称（事件详情，事件编辑，新增事件）
			editorShow: false, // 是否编辑
			allowAdd: false, // 是否允许新增
			addShow: true, // 是否新增
			operationShow: false, // 是否能够操作
			allowedToChange: false, // 是否显示修改按钮
			//编辑信息数据
			editorInfo: {
				dtime: '', // 爆破时间
				weather: '', // 天气
				location: '', // 爆破位置
				coordinates: '', // 坐标
				lng: '',
				lat: '',
				diameter: null, // 孔径
				hn: null, // 炮孔
				hd: null, // 孔深
				fl: null, // 填塞
				distance: null, // 孔距
				rd: null, // 排距
				md: null, // 单段最大装药量
				ad: null, // 爆破总药量
				explosive: null, // 爆破类型
				mode: null, // 起爆方式
				ctime: '', // 上报时间
				cname: '', // 检测单位
				pjname: '', // 项目名称
				bcname: '', // 爆破单位
				cid: null, //检测单位id
				pjid: null, //项目id
				id: null, //当前查看数据的id
				pn1: ''
			},
			// 爆破类型选择数据列表
			typesOfExplosiveList: [
				{
					value: 0,
					name: '露天深孔爆破'
				},
				{
					value: 1,
					name: '露天浅孔爆破'
				},
				{
					value: 2,
					name: '地下爆破'
				},
				{
					value: 3,
					name: '拆除爆破'
				},
				{
					value: 4,
					name: '水下爆破'
				},
				{
					value: 5,
					name: '其他爆破'
				}
			],
			// 起爆方式选择数据列表
			detonationWayList: [
				{
					value: 0,
					name: '电力起爆法'
				},
				{
					value: 1,
					name: '导爆管起爆法'
				},
				{
					value: 2,
					name: '导爆索起爆法'
				},

				{
					value: 3,
					name: '混合起爆法'
				}
			],
			//天气信息
			weatherList: [
				{
					value: 0,
					name: '晴'
				},
				{
					value: 1,
					name: '阴'
				},
				{
					value: 2,
					name: '雨'
				}
				// {
				// 	value: 0,
				// 	name: '阴'
				// },
				// {
				// 	value: 1,
				// 	name: '晴'
				// },
				// {
				// 	value: 2,
				// 	name: '雾'
				// },
				// {
				// 	value: 3,
				// 	name: '晴转多云'
				// },
				// {
				// 	value: 4,
				// 	name: '雨'
				// },
				// {
				// 	value: 5,
				// 	name: '小雨'
				// },
				// {
				// 	value: 6,
				// 	name: '中雨'
				// },
				// {
				// 	value: 7,
				// 	name: '大雨'
				// },
				// {
				// 	value: 8,
				// 	name: '雷阵雨'
				// },
				// {
				// 	value: 9,
				// 	name: '暴雨'
				// },
				// {
				// 	value: 10,
				// 	name: '小雪'
				// },
				// {
				// 	value: 11,
				// 	name: '中雪'
				// },
				// {
				// 	value: 12,
				// 	name: '大雪'
				// },
				// {
				// 	value: 13,
				// 	name: '雨夹雪'
				// },
				// {
				// 	value: 14,
				// 	name: '冰雹'
				// }
			],
			// 检测单位选择数据
			detectionUnit: [],
			// 项目名称选择数据
			projectName: [],
			imgList: '', //图片
			parameterOne: {
				limit: 1, //最大文件个数
				name: 'add-i' //上传文件组件的样式名字(多个组件同时存在时，该参数不能重复)
			}, //事件图片上传组件参数
			previewStatus: false, // 是否预览报告
			src: '', //报告预览地址
			rpid: '', //预览报告id
			dialogMap: false, //地图选点是否打开
			map: null, //地图对象
			localSearch: null, //地址模糊查询对象
			selectPoint: '', // 地图选点的经纬度
			inputName: '' // 地图选点输入的地址名称
		};
	},
	created() {
		var query = this.$route.query;
		this.parameter = query;
		this.getReportList();
		this.getPermissions();
	},
	mounted() {},
	computed: {},
	methods: {
		// 获取页面权限
		getPermissions() {
			this.axios.get('web2/event/odnr/gath').then(res => {
				if (res.status) {
					if (res.data.indexOf(153) != -1) {
						this.allowAdd = true;
					} else {
						this.allowAdd = false;
					}
				} else {
					this.allowAdd = false;
				}
			});
		},
		// 获取事件信息列表
		getReportList() {
			var data = {
				page: this.item.page,
				size: this.item.size,
				cid: this.parameter.cId, //公司id
				pjid: this.parameter.id //项目id
				// type: '',
			};
			data.type = 1;
			this.axios.post('web2/event/odnr/glist', data).then(res => {
				this.item.rows = [];
				this.item.total = 0;
				this.item.allPage = 0;
				if (res.status) {
					this.item.rows = res.data.array;
					this.item.total = res.data.total;
					this.item.allPage = Math.ceil(res.data.total / this.item.size);
				}
			});
		},
		// 新增事件条件接口请求（检测单位、项目名称）
		getConditions() {
			this.axios.get('web2/upblas/odnr/gcpjt').then(res => {
				this.detectionUnit = [];
				this.projectName = [];
				if (res.status) {
					this.detectionUnit = res.data;
					// this.projectName
				}
			});
		},
		// 检测单位选择事件
		detectionUnitSelect(val) {
			// console.log(val);
			var len = this.detectionUnit.length;
			for (let i = 0; i < len; i++) {
				let item = this.detectionUnit[i];
				if (item.id == val) {
					this.projectName = item.children;
					break;
				}
			}
		},
		// 打开新增弹窗
		openAdd() {
			// this.getConditions();
			this.getLocation();
			this.dialogName = '上报事件';
			this.dialogDetails = true;
			this.editorShow = false;
			this.addShow = true;
			this.operationShow = true;
			this.allowedToChange = false;
		},
		// 添加事件
		addEvent() {
			if (this.parameter.hasOwnProperty('cId')) {
				this.editorInfo.cid = this.parameter.cId;
			}
			if (this.parameter.hasOwnProperty('id')) {
				this.editorInfo.pjid = this.parameter.id;
			}
			// // console.log(this.editorInfo)
			var formData = new FormData();
			for (let key in this.editorInfo) {
				if (key != 'coordinates' && key != 'cname' && key != 'pjname' && key != 'bcname' && key != 'ctime' && key != 'pn1' && key != 'lng' && key != 'lat') {
					var p = '';
					if (typeof this.editorInfo[key] == 'number') {
						p = this.editorInfo[key].toString();
					} else {
						p = this.editorInfo[key];
					}
					if (key != 'diameter' && key != 'hn' && key != 'hd' && key != 'fl' && key != 'distance' && key != 'rd' && key != 'id') {
						var v = '';
						if (typeof this.editorInfo[key] == 'number') {
							v = this.editorInfo[key].toString();
						} else {
							v = this.editorInfo[key];
						}

						if (v == '') {
							// console.log(key);
							// console.log(v);
							this.$message({
								showClose: true,
								message: '请填写必填字段！',
								type: 'error'
							});
							return;
						} else if (key == 'location') {
							if (this.editorInfo.location.length > 20) {
								this.$message({
									showClose: true,
									message: '爆破位置不能超过20个字！',
									type: 'error'
								});
								return;
							}
						}
					}
					if (p != '' && p != null) {
						formData.append(key, this.editorInfo[key]);
					}
				}
			}
			var coordinates = this.editorInfo.coordinates.split(',');
			if (coordinates.length != 0) {
				formData.append('lng', coordinates[0]);
				formData.append('lat', coordinates[1]);
			}
			var file = this.$refs.uploadImgs.getFile();
			// // console.log(file);
			if (this.editorInfo.pn1 == '') {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			} else if (this.$refs.uploadImgs.getJudgeData()) {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			}

			this.axios.post('web2/event/ema/add', formData).then(res => {
				if (res.status) {
					this.getReportList();
					this.dialogDetails = false;
					this.editorShow = false;
					this.$message({
						showClose: true,
						message: '添加成功！',
						type: 'success'
					});
				}
			});
		},
		// 打开事件详情弹窗
		openDetails(data, e = null) {
			if (e != null) {
				$(e.currentTarget).css({
					color: '#901fbd'
				});
			}
			this.dialogName = '事件详情';
			for (let key in this.editorInfo) {
				if (key != 'dtime') {
					this.editorInfo[key] = data[key];
				} else {
					var Y = data[key].slice(0, 4);
					var M = data[key].slice(5, 7);
					var D = data[key].slice(8, 10);
					var h = data[key].slice(11, 13);
					var m = data[key].slice(14, 16);
					// // console.log(Y, M, D, h, m);
					this.editorInfo[key] = Y + M + D + h + m;
				}
			}
			this.editorInfo.coordinates = data.lng + ',' + data.lat;
			this.allowedToChange = data.uid == 1 && data.examine != 1;
			if (data.pn1 != '') {
				this.imgList = this.constData.imgUrl + 'res/static/bepc/' + data.pn1;
			}
			this.dialogDetails = true;
			this.editorShow = false;
			this.addShow = false;
			this.operationShow = false;
			setTimeout(() => {
				this.$refs.uploadImgs.clearFile();
			}, 300);
		},
		// 开启修改
		modifyStart() {
			this.operationShow = true;
			this.editorShow = true;
			this.addShow = false;
			this.$refs.uploadImgs.setFileData(this.imgList);
		},
		// 确认编辑
		confirmEditor() {
			if (this.parameter.hasOwnProperty('cId')) {
				this.editorInfo.cid = this.parameter.cId;
			}
			if (this.parameter.hasOwnProperty('id')) {
				this.editorInfo.pjid = this.parameter.id;
			}
			// console.log(this.editorInfo);
			var formData = new FormData();
			for (let key in this.editorInfo) {
				if (key != 'coordinates' && key != 'cname' && key != 'pjname' && key != 'bcname' && key != 'ctime' && key != 'pn1' && key != 'lng' && key != 'lat') {
					if (key != 'diameter' && key != 'hn' && key != 'hd' && key != 'fl' && key != 'distance' && key != 'rd') {
						var v = this.editorInfo[key].toString();
						if (v == '') {
							this.$message({
								message: '请填写必填字段！',
								type: 'error'
							});
							return;
						} else if (key == 'location') {
							if (this.editorInfo.location.length > 20) {
								this.$message({
									message: '爆破位置不能超过20个字！',
									type: 'error'
								});
								return;
							}
						}
					}
					formData.append(key, this.editorInfo[key]);
				}
			}
			var coordinates = this.editorInfo.coordinates.split(',');
			if (coordinates.length != 0) {
				formData.append('lng', coordinates[0]);
				formData.append('lat', coordinates[1]);
			}
			var file = this.$refs.uploadImgs.getFile();
			// console.log(file);
			if (this.editorInfo.pn1 == '') {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			} else if (this.$refs.uploadImgs.getJudgeData()) {
				if (file.length != 0) {
					formData.append('file', file[0].raw);
				}
			}
			this.axios.post('web2/upblas/cmc/mdinfo', formData).then(res => {
				if (res.status) {
					this.getReportList();
					this.dialogDetails = false;
					this.editorShow = false;
					this.$message({
						showClose: true,
						message: '修改成功！',
						type: 'success'
					});
				}
			});
		},
		// 删除事件
		deleteEvent(val = {}) {
			if (JSON.stringify(val) == '{}') {
				return;
			}
			var data = {
				id: val.id
			};
			this.$confirm('此操作将永久删除<strong>' + val.dtime + '</strong>的事件, 是否继续?', '警告', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning',
				dangerouslyUseHTMLString: true
			})
				.then(() => {
					this.axios.post('web2/upblas/cmc/duinfo', data).then(res => {
						if (res.status) {
							this.getReportList();
							this.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success'
							});
						}
					});
				})
				.catch(() => {});
		},
		// 删除报告
		deleteReport(val = {}) {
			if (JSON.stringify(val) == '{}') {
				return;
			}
			var data = {
				id: val.rpid
			};
			this.$confirm('此操作将永久删除该报告, 是否继续?', '警告', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.axios.post('web2/upblas/mmo/derp', data).then(res => {
						if (res.status) {
							this.getReportList();
							this.dialogDetails = false;
							this.editorShow = false;
							this.$message({
								showClose: true,
								message: '删除成功！',
								type: 'success'
							});
						}
					});
				})
				.catch(() => {});
		},
		// 预览报告
		preview(value, e = null) {
			if (e != null) {
				$(e.currentTarget).css({
					color: '#901fbd'
				});
			}

			this.rpid = value.rpid;
			var val = {
				id: value.rpid
			};
			this.axios.post('web2/upblas/odnr/srp', val, { responseType: 'arraybuffer' }).then(res => {
				// // console.log(res);
				const data = res;
				var headersName = res.headers['content-disposition'];
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						this.fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					var blob = new Blob([data], {
						type: 'application/pdf;charset=utf-8'
					});
					var href = window.URL.createObjectURL(blob);
					this.src = href;
					this.previewStatus = true;
				} else {
					// arraybuffer转字符串
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 下载PDF
		downloadPDF() {
			const link = document.createElement('a');
			link.style.display = 'none';
			link.href = this.src;
			link.setAttribute('download', this.fileName);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		// 下载Word
		downloadWord(val = {}, e = null) {
			if (e != null) {
				$(e.currentTarget).css({
					color: '#901fbd'
				});
			}
			if (JSON.stringify(val) == '{}') {
				return;
			}
			this.downloadFile(val);
		},
		// 关闭预览
		cancel() {
			this.previewStatus = false;
			if (this.examinationShow) {
				this.examinationShow = false;
			}
		},
		//批量下载源文件并打包
		downloadFile(value) {
			var data = {
				params: {
					ids: value,
					responseType: 'arraybuffer'
				}
			};

			this.axios.get('web2/upblas/odnr/dwrp', data).then(res => {
				// // console.log(res);
				var headersName = res.headers['content-disposition'];
				var fileName = '';
				// // console.log(headersName);

				if (res.headers['content-type'].split(';')[0] != 'application/json') {
					if (headersName != undefined) {
						fileName = decodeURIComponent(escape(headersName.split('=')[1]));
					}
					// data 是返回来的二进制数据
					const data = res;
					const url = window.URL.createObjectURL(new Blob([data]));
					const link = document.createElement('a');
					link.style.display = 'none';
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					// arraybuffer转json
					let resBlob = new Blob([res]);
					let reader = new FileReader();
					reader.readAsText(resBlob, 'utf-8');
					reader.onload = () => {
						var type = typeof JSON.parse(reader.result);
						// console.log(type);
						if (type == 'string') {
							this.$message({
								showClose: true,
								message: reader.result,
								type: 'error'
							});
						} else if (type == 'object') {
							let res = JSON.parse(reader.result);
							this.$message({
								showClose: true,
								message: res.message,
								type: 'error'
							});
						}
					};
				}
			});
		},
		// 定位
		getLocation() {
			var that = this;
			var toast = this.$loading({
				lock: true,
				text: '定位中',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			// 高德地图
			var map, geolocation;
			//加载地图，调用浏览器定位服务
			map = new AMap.Map('container', {
				resizeEnable: true,
				noGeoLocation: 1
			});
			map.plugin('AMap.Geolocation', function() {
				geolocation = new AMap.Geolocation({
					enableHighAccuracy: true, //是否使用高精度定位，默认:true
					timeout: 15000, //超过15秒后停止定位，默认：无穷大
					showButton: false, //显示定位按钮，默认：true
					showMarker: false, //定位成功后在定位到的位置显示点标记，默认：true
					showCircle: false, //定位成功后用圆圈表示定位精度范围，默认：true
					panToLocation: false //定位成功后将定位到的位置作为地图中心点，默认：true
				});
				map.addControl(geolocation);
				geolocation.getCurrentPosition();
				AMap.event.addListener(geolocation, 'complete', data => {
					toast.close();
					// console.log(data);
					that.editorInfo.coordinates = data.position.lng + ',' + data.position.lat;
					that.editorInfo.lng = data.position.lng;
					that.editorInfo.lat = data.position.lat;
					// console.log(that.editorInfo);
				}); //返回定位信息
				AMap.event.addListener(geolocation, 'error', error => {
					toast.close();
					// // console.log(error);
					that.editorInfo.coordinates = '';
					that.editorInfo.lng = '';
					that.editorInfo.lat = '';
					that.$message({
						showClose: true,
						message: '定位失败！',
						type: 'error'
					});
				}); //返回定位出错信息
			});
		},
		//翻页组件触发分页回调
		currentPageChange(type) {
			// console.log(type);
			if (type === 'event') {
				if (this.changeSize !== this.item.size) {
					this.item.size = this.changeSize;
					this.item.page = 1;
				}
				this.getReportList();
				// // console.log(123)
			}
		},
		// 设置时间
		setTime() {
			// this.info.time = new Date().format('yyyy-MM-dd hh:mm:ss');
		},
		// 关闭事件弹窗
		closeDialog() {
			// this.dialogDetails = false;
			this.reductionata();
			this.$refs.uploadImgs.clearFile();
		},
		// 还原数据
		reductionata() {
			for (let key in this.editorInfo) {
				if (typeof this.editorInfo[key] == 'string') {
					this.editorInfo[key] = '';
				} else {
					this.editorInfo[key] = null;
				}
			}
		},
		// 当前操作数据更改样式
		theSelected(e) {
			$('.list-box .scroll-box .conent-ul').removeClass('selected-data');
			$(e.currentTarget)
				.parent()
				.addClass('selected-data');
		},
		// 打开地图弹窗
		openMap() {
			this.dialogMap = true;
			setTimeout(() => {
				this.setMap(this.editorInfo.coordinates, 'pick-point');
			}, 300);
		},
		// 关闭地图弹窗
		closeMap() {
			this.selectPoint = '';
			this.inputName = '';
		},
		// 创建地图
		setMap(data, obj, val) {
			this.selectPoint = '';
			if (!this.map) {
				this.map = new BMap.Map(obj);
			}
			if (obj == 'pick-point') {
				setTimeout(() => {
					var lngLat = [];
					if (data != '') {
						lngLat = data.split(',');
					}
					if (lngLat.length != 0) {
						this.map.centerAndZoom(new BMap.Point(lngLat[0], lngLat[1]), 8);
					} else {
						this.map.centerAndZoom(new BMap.Point(104.065735, 30.659462), 8);
					}
					this.map.enableScrollWheelZoom(true);
				}, 200);
				this.setPoint(val);
			}
		},
		// 地图标点
		setViewMap(data) {
			// // console.log(data)
			var allOverlay = this.map.getOverlays();
			for (var i = 0; i <= allOverlay.length - 1; i++) {
				if (allOverlay[i].hasOwnProperty('markId')) {
					this.map.removeOverlay(allOverlay[i]);
				}
			}
			var i = require('../../assets/images/bicon.png');
			var myIcon = new BMap.Icon(i, new BMap.Size(40, 40), {
				imageSize: new BMap.Size(40, 40)
			});
			var marker1 = new BMap.Marker(data, {
				icon: myIcon,
				offset: new BMap.Size(0, -13),
				zIndex: 50
			});
			marker1.markId = 'mark';
			this.map.addOverlay(marker1);
			// this.map.setViewport([data])
		},
		// 地图选点
		setPoint(val) {
			this.map.addEventListener('click', e => {
				// console.log(e);
				this.selectPoint = '';
				var pointLng = e.point.lng;
				var pointLat = e.point.lat;
				this.setViewMap(e.point);
				var lnglats = this.BMapToAMap(pointLng, pointLat); // Array.<LngLat>
				// // console.log(lnglats)
				var Alng = lnglats.lng.toString();
				var Alat = lnglats.lat.toString();
				var lng = Alng.slice(0, Alng.indexOf('.')) + Alng.slice(Alng.indexOf('.'), 10);
				var lat = Alat.slice(0, Alat.indexOf('.')) + Alat.slice(Alat.indexOf('.'), 10);
				this.selectPoint = lng + ',' + lat;
			});
		},
		// 地图选点后确认将坐标赋值
		Assignment() {
			this.editorInfo.coordinates = this.selectPoint;
			var val = this.selectPoint.split(',');
			if (val.length != 0) {
				this.editorInfo.lng = val[0];
				this.editorInfo.lat = val[1];
			}
			this.dialogMap = false;
		},
		// 地图输入地址名称查询
		inquirePoint() {
			if (!this.localSearch) {
				this.localSearch = new BMap.LocalSearch(this.map, {
					renderOptions: {
						map: this.map
					}
				});
			}
			// 获取当前视图中心点
			var center = new BMap.Point(this.map.getCenter().lng, this.map.getCenter().lat);
			var geoc = new BMap.Geocoder();
			geoc.getLocation(center, rs => {
				var addComp = rs.addressComponents;
				this.localSearch.searchNearby(this.inputName, addComp.city);
				// 查询完成结果返回函数
				this.localSearch.setSearchCompleteCallback(point => {
					// // console.log(point)
				});
				this.localSearch.setMarkersSetCallback(point => {
					// console.log(point);
					var allOverlay = this.map.getOverlays();
					for (var i = 0; i < allOverlay.length - 1; i++) {
						// console.log(allOverlay[i]);
						allOverlay[i].addEventListener('click', e => {
							// // console.log(e)
							this.setViewMap(e.point);
							e.domEvent.stopPropagation();
						});
					}
				});
			});
		}
	},
	components: {
		uploadImg
	},
	beforeDestroy() {},
	watch: {}
};
</script>

<style lang="scss">
// 事件详情
.detailsDialog {
	width: 650px !important;
	height: 700px;
	margin-top: 165px !important;

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}

	.el-dialog__body {
		width: calc(100% - 20px);
		height: calc(100% - 120px);
		padding: 10px 10px 0 10px;
	}

	.dialog-footer {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.details {
		width: 100%;
		height: 100%;

		.tips-i {
			color: #ff0000;
			margin-right: 3px;
		}

		// 展示内容
		.cell-box {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;

			.cell,
			.cell-s {
				display: flex;
				justify-content: flex-start;
				align-items: baseline;

				.title {
					width: 81px;
					font-size: 14px;
					color: #909399;
					white-space: nowrap;

					.letter {
						display: inline-block;
						width: 65%;
						letter-spacing: 28px;
						white-space: nowrap;
					}
				}

				.content {
					width: calc(100% - 91px);
					font-size: 15px;
					color: #000;
				}

				.sunny {
					color: #ffd54f;
					font-size: 26px;
				}

				.Yin {
					color: #909399;
					font-size: 26px;
				}

				.rain {
					color: #b3dbff;
					font-size: 26px;
				}

				.el-select {
					width: calc(100% - 81px);

					.el-input {
						width: 100%;
					}

					.el-input__icon {
						line-height: 32px;
					}

					.el-input__suffix {
						right: 0;
					}
				}

				.el-input {
					width: calc(100% - 81px);

					.el-input__inner {
						height: 32px !important;
						line-height: 32px;
						padding: 0 25px 0 10px;
						font-size: 15px;
						color: #000000;
					}
				}

				.el-date-editor {
					.el-input__inner {
						padding: 0 30px;
					}

					.el-input__icon {
						line-height: 32px;
					}
				}
			}
			// 展示样式
			.show {
				.el-input__inner {
					border: none;
					padding: 0 !important;
				}

				.el-input-group__append,
				.el-input__suffix,
				.el-input__icon {
					display: none;
				}

				.el-input.is-disabled .el-input__inner {
					background: none;
					cursor: text;
				}
			}

			.cell {
				width: calc(50% - 4px);
			}

			.cell-s {
				width: calc(33% - 4px);

				.title {
					width: 85px;
				}
			}

			.long-title {
				.title {
					width: 155px;
				}

				.el-input {
					width: calc(100% - 155px);
				}
			}

			.long-title-s {
				.title {
					width: 127px;
				}

				.el-input {
					width: calc(100% - 127px);
				}
			}
		}
		// 图片
		.img-box {
			width: 100%;
			height: 165px;
			display: flex;
			justify-content: center;
			align-items: center;

			.image-slot {
				height: 90%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				i {
					font-size: 50px;
				}

				span {
					font-size: 20px;
				}
			}
		}
	}
}
// 地图选点弹窗
.mapDialog {
	width: 660px;
	margin-top: 165px !important;

	.el-dialog__body {
		padding: 0;
	}

	.pop-ups-content {
		width: 100%;
		height: 655px;
		background-color: #ffffff;
		position: relative;
		padding: 0;

		.input {
			padding: 10px;
			border-radius: 10px;
			background-color: #ddd;
			position: absolute;
			top: 20px;
			z-index: 1000;

			.el-input {
				width: 180px;

				.el-input__inner {
					height: 35px;
					line-height: 35px;
				}
			}
		}

		.right {
			right: 10px;
		}

		.left {
			left: 10px;
		}

		#pick-point {
			width: 100%;
			height: 100%;
		}
	}
}
</style>
<style scoped lang="scss">
.police-box {
	width: 100%;
	height: 100%;

	.box-title {
		height: 60px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 20px 0 32px;

		.title-text {
			font-size: 17px;
			font-weight: 600;
			color: #303133;
			letter-spacing: 1px;
		}

		.right {
			::v-deep {
				.el-input__inner {
					height: 35px;
					line-height: 35px;
				}
			}
		}
	}

	.list-box {
		width: 100%;
		height: calc(100% - 112px);

		// 当前操作样式
		.selected-data {
			background-color: rgba($color: #b3d8ff, $alpha: 1) !important;
		}

		ul {
			width: calc(100% - 30px);
			padding: 0 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: thin solid #dcdfe6;

			li {
				text-align: center;
				white-space: nowrap;
			}

			.align-left {
				text-align: left;
			}

			.time {
				width: 150px;
			}

			.time-l {
				width: 180px;
			}

			.width-s {
				width: 60px;
			}

			.width-l {
				width: 100px;
			}

			.width-xl {
				width: 180px;
			}

			.width-icon {
				width: 50px;

				i {
					font-size: 20px;
				}
			}

			.icon-but {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.view {
					font-weight: 600;
					color: #3a8f6a;
				}
			}

			.txt-but {
				display: flex;
				justify-content: space-around;
				align-items: center;

				.el-button {
					padding: 5px 8px;
					color: #409eff;
				}
			}

			.align-left {
				text-align: left;
			}
		}

		.head-ul {
			height: 45px;
			background-color: #ebeef5;
			font-size: 15px;
			font-weight: 600;
			color: #4c4e51;
			letter-spacing: 1px;
		}

		// // 审批通过颜色
		// .through{
		// 	background-color: rgba($color: #67C23A, $alpha: 0.2);
		// }
		// // 审批不通过颜色
		// .notThrough{
		// 	background-color: rgba($color: #E6A23C, $alpha: 0.2);
		// }
		.blue-color {
			color: #00a5ec;
		}

		.red-color {
			color: #f56c6c;
		}

		.scroll-box {
			width: 100%;
			height: calc(100% - 40px);

			.conent-ul {
				height: 39px;
				line-height: 39px;
				font-size: 14px;
				color: #606266;
			}

			.conent-ul:nth-child(odd) {
				background-color: #f2f2f2;
			}

			.conent-ul:nth-child(even) {
				background-color: #ffffff;
			}

			.conent-ul:hover {
				background-color: #ebeef5;
			}

			.conent-ul:last-child {
				border-bottom: thin solid #dcdfe6;
			}
		}
	}

	.bottom-box {
		position: relative;
		width: 100%;
		height: 48px;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 48px;
		flex: 0 0 48px;

		.sizeBox {
			float: left;
			line-height: 30px;
			margin: 9px 10px 0 30px;

			input {
				width: 70px;
				text-indent: 0;
				text-align: center;
				margin: 0 5px;
				line-height: 24px;
				height: 24px;
				border-radius: 2px;
				border: 1px solid #e5e5e5;
			}
		}

		.tableFooter {
			width: auto;
			display: inline-block;
			position: absolute;
			float: left;
			background: none;
		}
	}

	// 报告预览
	.preview-box {
		position: fixed;
		// display: none;
		z-index: 10000;

		.pop-ups-content {
			width: 1190px;
			height: 100%;
			margin-top: 10px;

			.mian-box {
				width: 100%;
				height: 98%;
				display: flex;

				.left-box {
					height: 100%;
				}

				.right-box {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;

					::v-deep {
						.el-button {
							padding: 0;
							width: 56px;
							height: 56px;
							margin-left: 14px;
							margin-bottom: 15px;
						}
					}

					.but-content {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-around;
						align-items: center;

						.icon {
							color: #409eff;
							font-size: 22px;
						}

						.txt {
							font-size: 12px;
							margin-left: 0;
						}

						.examination {
							font-size: 26px;
							color: #409eff;
						}

						.iconhoutaitubiao-25 {
							font-size: 35px !important;
						}

						span:last-child {
							color: #000;
						}
					}
				}
			}
		}
	}
}
</style>
